import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, format: string = 'fullDate'): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang)

    switch (format) {
      case 'fullDate':
        return datePipe.transform(value, 'fullDate')
      case 'daymonth':
        return datePipe.transform(value, 'EEEE, MMMM d')
      case 'shortDate':
        return datePipe.transform(value, 'shortDate')
      case 'shortDayTime':
        return datePipe.transform(value, 'E M/d, HH:mm')
      case 'anniversaire':
        return datePipe.transform(value, 'dd MMMM')
      case 'time':
        return datePipe.transform(value, 'HH:mm')
      case 'shortDayMonth':
        return datePipe.transform(value, 'EEE, d MMM y')
      default:
        return datePipe.transform(value, 'fullDate')
    }

    // usage in template: {{ date | localizedDate:'daymonth' }}
  }
}
