export default {
  MODAL_DICTIONARY: {
    MODAL: 'Tests Management',
    HEADER: 'Tests',
    TITLE: 'Manage tests for Job Offers.',
    ADD: 'Add a test',
    SELECT: 'Select All',
    DELETE: 'Delete',
    EDIT: 'Edit',
    RESPONSE: 'Answer',
    DIFFICULTY: 'Difficulty',
    NBQ: 'Number of Questions',
    SKILL: 'Skill',
    DETAIL: 'Details',
    DETAILEX: 'Hide Details',
    FAILED: "Please specify the test's name, difficulty level and ensure there is at least one question.",
    GFAILED:
      "Please specify the test's name, difficulty level, language and ensure that the number of questions is between 1 and 20.",
    PHEADER: 'Add Question test settings',
    PPARAM: 'Parameters',
    PNAME: 'test Name',
    PNAMEPH: 'test Name',
    LANGUAGE: 'Language',
    PLANGUAGE: 'Choose a language',
    PFA: 'Easy',
    PDIF: 'Difficult',
    PMO: 'Moderate',
    PNBQ: 'Number of generated questions',
    PNBQPH: 'Specify a number between 1 et 20',
    INSTRUCTIONS: 'Instructions',
    PINSTRUCTIONS: 'Generate MCQs',
    PADD: 'Add a question',
    PGEN: 'Generate questions',
    CANCEL: 'Cancel',
    PSAV: 'Save',
    PSKILL: 'Rechercher une compétence.',
    HEADERMAIL: 'Email management',
    POOLTITLE: 'Name',
    SEARCH: 'search',
    RECCOM: 'It is recommended not to modify the questions generated by the AI!',
    WARN1: 'Please save the questions before proceeding.',
    WARN2: 'Please fill in all fields and ensure there is at least one question.',
    WARN3: 'Not enough credits to generate questions.',
    PERRNUM: 'Please enter a number between 1 and 20.',
    DESCRIPT: 'Description',
    CAT: 'Category',
    PCAT: 'Select a category',
    PDESCRIPT: 'write a description for the test',
  },

  MODAL_TEST: {
    MODECLASSIQUE: 'Classic Mode',
    MODEKANBAN: 'Kanban Mode',
    CANDIDATCOMPETENCE: 'Candidate Skills',
    DATESOUMISSION: 'Application Submission Date',
    TESTENVOYEPASPASSE: 'Evaluation Sent But Not Yet Completed',
    NOMBREEMAILSENVOYE: 'Number of Emails Sent',
    TESTENVOYEPASSE: 'Evaluation Sent and Completed',

    HEADER: 'Evaluations list',
    ADD: 'Add an Evaluation',
    NAME: 'Name',
    DUR: 'Duration',
    NUM: 'Number of Questions',
    SEARCH: 'Search',
    VIEW: 'Questions Overview',
    QUES: 'No questions available',
    SEL: 'Select a test',
    REM: 'Remove',
    PHEADER: 'Add evaluation settings',
    ERR: 'Please fill in all fields, ensure at least one test is included, and confirm the duration is greater than 0.',
    DADD: 'Number of questions',
    ENAME: "Enter the evaluation's name",
    EDUR: "Enter the evaluation's duration",
    ENUM: 'Enter the number of questions',
    SELECT_DICTIONARY: 'Select a test',
    PERRNUM: 'Please enter a number between 1 and',
    ERRDUR: 'Please enter a duration greater than 0.',
    SIM: 'Simulate',
    SIML: 'Loading simulation',
    SIMC: 'Finish',
  },

  MODAL_OFFER: {
    ASSO: 'Associate an evaluation',
    ASSOE: 'Associate an automatic evaluation dispatch to a step',
    AUC: 'No evaluation',
    AUCS: 'No step',
    VAL: 'Please select a valid evaluation.',
    SEND: 'Send an evaluation',
    RSEND: 'Resend an evaluation',
    RES: 'Evaluation result',
    CRES: 'Candidate result',
    INF: 'Candidate information',
    NAME: 'Name',
    QUES: 'Total Questions',
    BR: 'Visual Breakdown',
    SS: 'Score by skill',
    SP: 'Score by test',
    SKILL: 'Skills',
    POOL: 'Tests',
    DRES: 'Detailed Results',
    CA: 'Candidate Answer',
    CRA: 'Expected Answer',
    EXIT: 'QUIT',
    CORR: 'Correct Answers',
    QCORR: 'Correct',
    QINC: 'Incorrect',
    SELECT_TEST: 'Select an evaluation',
    SELECT_STEP: 'Select a step',
    ERT: 'An unexpected error occurred.',
    TES: 'Evaluation sent successfully.',
    TED: 'Evaluation already sent.',
    TS: 'Time spent by the candidate',
    NOTES: 'The candidate’s professional skills as indicated on their CV',
  },
  MODAL_CONFIRM: {
    MESSAGE: 'Careful!\nAll results and progress will be lost.\nAre you sure you want to resend the evaluation to ',
    MESSAGEE: 'Please fill the required fields to send the email to ',
    MESSAGEEET: 'Please fill the required fields to send the email to the candidates.',
    ANN: 'Cancel',
    SUB: 'Send',
    NEXP: 'Name of the sender',
    EEXP: 'Email of the sender',
    NPH: 'Enter the name of the sender',
    EPH: 'Enter the email of the sender',
  },

  MODAL_CHART: {
    DOWNLOAD: 'Download as',
    OTH: 'Unspecified',
  },

  MODAL_DICTIONARY_HELP: {
    HEAD: 'How to Use This Feature',
    BODY: `
   <ul>
      <h5>Mandatory Information:</h5>
      <ul>
        <li>test Name</li>
        <li>Difficulty Level</li>
        <li>At Least One Question Card</li>
      </ul>
      <br />
      <h5>Instructions:</h5>
      <li>Use the left section to input test details, including name, skill, and category.</li>
      <li>test the difficulty level using the provided buttons.</li>
      <li>Select a category for your test.</li>
      <li>Add a brief description to your test.</li>
      <br />
      <h5>To Generate Questions Using AI:</h5>
      <li>Specify the number of questions (1–20) and select a language. (It is recommended to use a fixed language for the entire test.)</li>
      <li>(Optional) Add specific instructions for the AI to tailor the output to your desired outcome.</li>
      <li>Click <strong>"Generate questions"</strong> to create the questions.</li>
      <br />
      <h5>Review and Save:</h5>
      <li>Use the right section to review the generated questions and add custom ones by clicking <strong>"Add a question"</strong>.</li>
      <li>Click the <strong>"Save"</strong> button to add the test to the system.</li>
    </ul>`,
    HIDE: 'Hide',
  },
}
