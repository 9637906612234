export default {
  REFUSCANDIDAT: 'Reject the candidate',
  COPIER: 'Copy',
  COPIEREMAIL: 'Copy the email address',
  COPIEEMAILSUCCESS: 'Email copied successfully',
  COPIEQUESTIONS: 'Copy questions',
  COPIEQUESTIONSSUCCESS: 'Questions successfully copied',
  FERMER: 'Close',
  SUGGESTIONQUESTIONS: 'Here is a suggestion of 10 questions:',
  QUESTIONSPOSERLORSENTRETIEN: 'Suggested questions to ask candidates during the interview',
  ASSIGNEROFFRE: 'Assign to an offer',
  EFFECTUERACTION: 'Perform an action',
  AUTRESCANDIDATS: 'Other candidates',
  CANDIDATS: 'Candidates',
  TRIERPARDATE: 'Sort by date',
  PLUSRECENT: 'Most recent',
  PLUSANCIEN: 'Oldest',

  AUCUNCANDIDATTROUVE: 'Currently, no candidates have been found.',
  CVSOUMIS: 'This CV has been submitted for the position of',
  PASPOSTEAPPLIQUE: 'No position applied',
  COMPTERENDUCV: 'CV report',
  APROPOS: 'About the candidate',
  NOMPRENOM: 'Full name',
  NATIONALITE: 'Nationality',
  DATENAISSANCE: 'Date of birth',
  ADRESSE: 'Address',
  EMAIL: 'Email',
  NUMTELEPHONE: 'Phone number',
  NOM: 'Last name',
  PRENOM: 'First name',
  HISTORIQUE: 'History',
  CORRESPONDANCEAVEC: 'Match with the position',
  RELANCERANALYSE: 'Re-run the analysis by selection criteria',
  LANCERANALYSE: 'Run the analysis by selection criteria',
  OFFREEMPLOI: 'Job offer',
  ANALYSE: 'Analysis',
  CANDIDATNECORRESPONDPAS: 'The candidate does not match the selection criteria for the job offer.',
  ANALYSEPOUSSE: 'Advanced analysis by AI is available only for the candidate attached to the offer.',
  QUESTIONSSUGGEREES: 'Suggested questions',
  COMPETENCES: 'His/Her skills',
  POSTESUGGERES: 'Suggested positions',
  PASPOSTESUGGERE: 'No suggested positions',
  NOTES: 'Notes',
  EXPERIENCE: 'Experience',
  FORMATION: 'Education',
  CERTIFICATIONS: 'Certifications',
  LESDOCUMENTSDECANDIDAT: 'Candidate documents',
  AJOUTERUNDOCUMENT: 'Add a document',
  PROCESSUSDERECRUTEMENT: 'Recruitment process',
  ASSIGNERcetteoffre: 'Assign to this offer',
  SUGGERERCANDIDAT: 'Suggest candidates',
  LISTECANDIDATSSUGGERER: 'List of suggested candidates',
  MESCANDIDATS: 'My candidates',
  CANDIDATDEJAASSIGNE: 'This candidate is already assigned to this offer',
  CANDIDATNONASSIGNE: 'Assign this candidate to this offer',
  RENSEIGNERFROMDOCUMENT: 'Fill in from a document',
  INFOPERSONELLE: 'Personal Information',
  LIEUNAISSANCE: 'Place of birth',
  CODEPOSTAL: 'Postal code',
  COMPLEMENTADRESSE: 'Address complement',
  COORDONNEES: 'Contact details',
  DOCUMENTIDENTITEVERSO: 'ID document back side',
  DOCUMENTIDENTITERECTO: 'ID document front side',
  DOCUMENTSIDENTITE: 'Identity documents',
  NUMEROSECUSOCIALE: 'Social security number',
  VILLE: 'City',
  VOIRDOCEXISTANT: 'View existing document',
  REINITIALISER: 'Reset',
  AUTORISATIONSADMINISTRATIVES: 'Administrative Authorizations',
  MANAGER: 'Manager',
  EMPLOIETPOSITION: 'Job and Position',
  NOMDUCONTACTURGENT: 'Emergency Contact Name',
  NUMERODUCONTACTURGENT: 'Emergency Contact Number',
  NOMBREDENFANTS: 'Number of Children',
  VEUF: 'Widowed',
  DIVORCE: 'Divorced',
  MARIE: 'Married',
  CELIBATAIRE: 'Single',
  SITUATIONFAMILIALE: 'Family Situation',
  SITUATIONFAMILIALEETURGENCE: 'Family Situation and Emergency',
  TELEPHONEPERSONNEL: 'Personal Phone',
  TELEPHONEPROFESSIONNEL: 'Professional Phone',
  GENRE: 'Gender',
  HOMME: 'Male',
  FEMME: 'Female',
  AUTRE: 'Other',
  
  COMMUNITY: 'Community',
  ENTERPRISE: 'Enterprise',
  MEMBER: 'Member',
  DESCRIPTION: 'Description',
  TITLE: 'Title',
  ACTIONS: 'Actions',
  NBR: 'Number',
  FNAME: 'First Name',
  LNAME: 'Last Name',
  CREATED_AT: 'Created At',
  IMG: 'Image',
  NAME: 'Name',
  SEARCH: 'Search',
  IN_ANY_FIELD: 'in any field',
  BTN_DELETE: 'Delete',
  BTN_SAVE: 'Save',
  BTN_CONFIRM: 'Confirm',
  BTN_CANCEL: 'Cancel',
  BTN_ADD: 'Add',
  ALLOWED_FILE_TYPES: 'Allowed file types',
  THE_FIELD: 'Field ',
  INPUT_VALIDATION: {
    MIN: ` should have at least {{min}} characters`,
    MAX: ` should have at most {{max}} characters`,
  },
  SHOWING_ROWS_TABLE: 'Showing rows {{from}} to {{to}} of {{of}}',
  OPERATION_SUCCESS: 'Operation was successful!',
  OPERATION_FAILED: 'Operation failed!',
  OPERATION_WARNING: 'This strategy is available in a premium plan! Please upgrade to premium plan to use it!',

  EXAMPLE: 'Example',
  LANG: 'Language',
  DAY_OF_WEEK: {
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THU: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
    SUN: 'Sunday',
  },
  ADDRESS: 'Address',
  YES: 'Yes',
  NO: 'No',
  FROM: 'From',
  TO: 'To',
  HEIGHT: 'Height',
  WIDTH: 'Width',
  COLOR: 'Color',
  ERRORS: {
    OCCURED_TRY_AGAIN: 'An error occurred, please try again!',
    AI_QUOTA_EXCEEDED: 'Your AI quota is exceeded. Please contact us to request an increase.',
  },
  VIEW: 'View',
  EDIT: 'Edit',
  DELETE: 'Delete',
  CREATE: 'Create',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  CONFIRM: 'Confirm',
  ADD: 'Add',
  ASSIGN: 'Assign',
  FIND_USER: 'Find an employee',
  FIND_USERS: 'Find employees',
  MONTHS: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },
  All: 'All',
}
